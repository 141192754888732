import { CustomerTypeEnum } from '@shared/enums/customer-type.enum';
import { OrderFormatEnum } from '@shared/enums/order-format.enum';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';

export abstract class AbstractOrderData {
  customerId: number;
  customerType: CustomerTypeEnum;
  orderFormat: OrderFormatEnum;
  orderType: OrderTypeEnum;
}
